<template>
  <div class="w-100">
    <basic-subheader>
      <template v-slot:actions>
        <b-row>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-button class="btn mr-4" type="button" @click="returnPage">
                <span class="svg-icon">
                  <inline-svg
                    src="/media/svg/icons/Neolex/Basic/arrow-left.svg"
                  />
                </span>
                Hủy
              </b-button>
              <b-button
                class="btn btn-success"
                type="button"
                @click="handleValidate"
              >
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
                </span>
                Lưu lại
              </b-button>
            </div>
          </b-col>
        </b-row>
      </template>
    </basic-subheader>
    <b-overlay :show="loading">
      <FormCoachProfile ref="form" :detail="detail" @submit="submit" />
    </b-overlay>
  </div>
</template>
<script>
export default {
  name: 'CoachProfileCreate',
  components: {
    FormCoachProfile: () => import('./components/FormCoachProfile.vue'),
  },
  data() {
    return {
      loading: false,
      detail: null,
    };
  },
  created() {
    this.getDataPrepare();
  },
  methods: {
    returnPage() {
      this.$router.go(-1);
    },
    async getDataPrepare() {
      try {
        this.loading = true;
        const { meta, data, error } = await this.$api.get(
          '/Admin/Account/portal/PrepareCreateUpdateFormItem',
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }
        this.detail = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    handleValidate() {
      this.$refs.form.handleValidate();
    },
    async submit(params) {
      try {
        this.loading = true;
        if (params.file) {
          const fileId = await this.uploadImage(params.file);
          if (!fileId) return;
          delete params.file;
          params['coverId'] = fileId;
        } else if (this.detail.image) {
          params['coverId'] = this.detail.image.id;
        }

        const { meta, error } = await this.$api.post(
          '/Admin/Account/portal',
          params,
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }
        this.$toastr.s({
          title: 'Thành công !',
          msg: `Tạo mới tài khoản thành công`,
        });
        this.returnPage();
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    async uploadImage(file) {
      // upload file
      const { error, data } = await this.$store.dispatch(
        'context/UPLOAD_IMAGE',
        file,
      );
      if (error) {
        this.$toastr.s({
          title: 'Lỗi !',
          msg: error,
        });
        return null;
      }

      return data.id;
    },
  },
};
</script>
<style lang=""></style>
